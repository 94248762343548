import * as Yup from "yup";
import { Errors } from "../../api/errors";
import {
  ILoginPayload,
  IRegisterPayload,
  IRegistrationUser,
} from "../../api/types/authentication";
import {
  IForgotPasswordPayload,
  IResetPasswordForm,
} from "../../api/types/verify";
import {
  EmailValidator,
  LanguageValidator,
  NameValidator,
  OrganizationSchema,
  PasswordValidator,
  PhoneValidator,
} from ".";

export const RegistrationUserSchema = Yup.object().shape<IRegistrationUser>({
  name: NameValidator.required(Errors.NameRequired),
  email: EmailValidator.required(Errors.EmailRequired),
  phoneNumber: PhoneValidator.required(Errors.PhoneNumberRequired),
  password: PasswordValidator.required(Errors.PasswordRequired),
  role: Yup.string().required(Errors.RoleRequired),
  language: LanguageValidator.required(Errors.LanguageRequired),
});

export const RegisterSchema = Yup.object().shape<IRegisterPayload>({
  user: RegistrationUserSchema.required(Errors.UserRequired),
  organization: OrganizationSchema.optional().default(undefined),
  organizationAvatarId: Yup.string().optional(),
  invitationToken: Yup.string().optional(),
});

export const LoginSchema = Yup.object().shape<ILoginPayload>({
  email: EmailValidator.required(Errors.EmailRequired),
  password: PasswordValidator.required(Errors.PasswordRequired),
});

export const ForgotPasswordSchema = Yup.object().shape<IForgotPasswordPayload>({
  email: EmailValidator.required(Errors.EmailRequired),
});

export const ResetPasswordSchema = Yup.object().shape<IResetPasswordForm>({
  password: PasswordValidator.required(Errors.PasswordRequired),
});

import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
import { useLanguage } from "../hooks/useLanguage";

const useStyles = makeStyles(() => ({
  icon: {
    height: "24px",
  },
}));

export default function SmallHeader() {
  const classes = useStyles();
  const { onLanguageChange, language } = useLanguage();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <img src={"/static/images/logo.svg"} className={classes.icon} />
      <Button onClick={onLanguageChange} variant="outlined">
        {language === "fr" ? "EN" : "FR"}
      </Button>
    </Box>
  );
}
